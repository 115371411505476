
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useChannel from "@/core/services/compositions/enum/useChannel";
import useGateway from "@/core/services/compositions/enum/useGateway";
import Swal from "sweetalert2";
import { checkPermission } from '@/router/authentication';

interface Client {
  type: string;
  name: string;
  channel: string;
  gateway: string;
  email: string;
  webhook_url: string;
  allowed_services: string;
  allowed_ip: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { channel } = useChannel();
    const { gateway } = useGateway();
    const router = useRouter();

    // breadcrumb
    setNewPageBreadcrumbs(t("management.client.create"), [
      { title: t("management.client.create") }
    ]);

    const create = reactive<Client>({
      type: '',
      name: '',
      email: '',
      channel: '',
      gateway: '',
      webhook_url: '',
      allowed_services: '',
      allowed_ip: '',
    });

    const resetForm = () => {
      create.type = '';
      create.name = '';
      create.channel = '';
      create.gateway = '';
      create.email = '';
      create.webhook_url = '';
      create.allowed_ip = '';
      create.allowed_services = '';
    }

    const isGranted = checkPermission('institution-modifier').isGranted;

    const submitForm = () => {
      if (create.type == '') {
        showErrorPopup({
          title: 'Validation',
          text: 'Client type cannot empty',
          message: 'Client type cannot empty',
        })

        return;
      }

      if (create.name == '') {
        showErrorPopup({
          title: 'Validation',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      if (create.email == '') {
        showErrorPopup({
          title: 'Validation',
          text: 'Email cannot empty',
          message: 'Email cannot empty',
        })

        return;
      }

      const payload = {
        name: create.name,
        email: create.email,
        webhook_url: create.webhook_url,
        allowed_services: create.allowed_services,
        allowed_ip: create.allowed_ip,
      };

      if (create.type == 'gateway') {
        if (create.gateway == '') {
          showErrorPopup({
            title: 'Validation',
            text: 'Gateway cannot empty',
            message: 'Gateway cannot empty',
          })

          return;
        }

        payload['clientable'] = create.gateway
      } else {
        if (create.channel == '') {
          showErrorPopup({
            title: 'Validation',
            text: 'Channel cannot empty',
            message: 'Channel cannot empty',
          })

          return;
        }

        payload['clientable'] = create.channel
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      ApiService.setHeader();
      ApiService.post(
        `app/entity/client/store`,
        payload as any
      )
        .then((data) => {
          if (!isGranted) {
            showSuccessPopup({
              title: 'Status',
              text: 'Success'
            });
          } else {
            Swal.fire({
              showClass: {
                popup: "mumtaz-success-popup"
              },
              icon: "success",
              title: 'Success',
              text: 'Continue to create new institution?',
              showDenyButton: true,
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                router.push({ name: "app.management.institution.create", query: { client: data.data.data.hash } });
              }
            });
          }

          resetForm();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      create,
      translate,
      submitForm,
      submitButton,
      resetForm,
      channel,
      gateway,
      checkPermission
    };
  },
  methods: {}
});
